import { LegacySearchIndexCourse } from '@shared/types';
import { FunctionComponent, HTMLAttributes, useMemo } from 'react';
import DeliveryIndicator from '../DeliveryIndicator';

type SearchIndexCourseDeliveryProps = Omit<HTMLAttributes<HTMLParagraphElement>, 'children'> & {
    course: LegacySearchIndexCourse;
};

export type CourseDeliveryProps = SearchIndexCourseDeliveryProps;

const getSearchIndexCourseLocation = (course: LegacySearchIndexCourse): string => {
    if (course.delivery.length === 1 && course.delivery[0] === 'online') {
        return 'Live online';
    }

    const uniqueCities = (course.cities || []).filter(
        (city, index) => course.cities.indexOf(city) === index
    );

    if (course.available_internationally) {
        return `Internationally`;
    }

    const cityLabel =
        course.city && uniqueCities.length > 1
            ? `${course.city} + ${uniqueCities.length - 1} more`
            : course.city || '';

    if (course.available_uk_wide && cityLabel) {
        return `${cityLabel} or UK Wide`;
    }

    if (!course.city && !course.delivery.includes('on-request')) {
        return '';
    }

    return cityLabel;
};

export const CourseDeliveryDetails: FunctionComponent<CourseDeliveryProps> = ({
    course,
    ...props
}) => {
    const location = useMemo(() => {
        return getSearchIndexCourseLocation(course);
    }, [course]);

    if (!course.delivery) return null; //should not be possible

    return (
        <DeliveryIndicator
            delivery={course.delivery}
            locations={[location]}
            courseKeyword={course.keyword || 'course'}
        />
    );
};

export default CourseDeliveryDetails;
