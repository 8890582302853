import { Course as CourseSchema, CourseInstance, Offer } from 'schema-dts';
import { convert as html2PlainText, HtmlToTextOptions } from 'html-to-text';
import { Occurrence, SearchIndexClassCourse, SearchIndexCourse } from '@shared/types';
import { Temporal } from 'temporal-polyfill';

const html2PlainTextOptions: HtmlToTextOptions = {
    selectors: [{ selector: 'img', format: 'skip' }],
};

const fallbackImageUrl =
    'https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjExODk3Mn0';

type ProviderTypes = {
    '@type': 'EducationalOrganization';
    '@id': string;
    name: string;
    sameAs: string;
    url: string;
};

type SharedSchemaTypes = {
    name: string;
    description: string;
    image: string;
    offers: Offer[];
};

export const getWorkload = (durationInMinutes?: number | null): string => {
    if (!durationInMinutes) return 'PT';
    // Google wants courseWorkload as a 8601 duration format: https://developers.google.com/search/docs/appearance/structured-data/course-info#course-instance-sd
    const minutesPerHour = 60;
    const hoursPerDay = 24;
    const daysPerWeek = 7;

    const weeks = Math.floor(durationInMinutes / (minutesPerHour * hoursPerDay * daysPerWeek));
    const days = Math.floor(
        (durationInMinutes % (minutesPerHour * hoursPerDay * daysPerWeek)) /
            (minutesPerHour * hoursPerDay)
    );
    const hours = Math.floor((durationInMinutes % (minutesPerHour * hoursPerDay)) / minutesPerHour);
    const remainingMinutes = durationInMinutes % minutesPerHour;

    let duration = 'P';
    if (weeks > 0) duration += `${weeks}W`;
    if (days > 0) duration += `${days}D`;
    if (hours > 0 || remainingMinutes > 0) {
        duration += 'T';
        if (hours > 0) duration += `${hours}H`;
        if (remainingMinutes > 0) duration += `${remainingMinutes}M`;
    }

    return duration;
};

type GoogleSupportedCourseModes = 'Online' | 'Blended' | 'Onsite';

const getCourseMode = (
    course: SearchIndexCourse,
    occurrence?: { delivery: Occurrence['delivery'] }
): GoogleSupportedCourseModes => {
    if (occurrence && occurrence.delivery) {
        if (occurrence.delivery === 'in-person') return 'Onsite';
        if (occurrence.delivery === 'hybrid') return 'Blended';
        return 'Online';
    }
    if (course.delivery_methods.includes('in-person')) return 'Onsite';
    if (course.delivery_methods.includes('hybrid')) return 'Blended';
    return 'Online';
};

const getInstances = (
    course: SearchIndexCourse,
    occurrences: SearchIndexClassCourse['occurrences']
): CourseInstance[] | undefined => {
    if (course.type === 'on-demand') {
        return [
            {
                '@type': 'CourseInstance',
                courseMode: 'Online',
                courseWorkload: getWorkload(course.duration),
            },
        ];
    }

    if (course.type === 'on-request') {
        const workload = getWorkload(course.duration);
        const onlineInstance: CourseInstance[] = course.available_online
            ? [
                  {
                      '@type': 'CourseInstance',
                      courseMode: 'Online',
                      courseWorkload: workload,
                  },
              ]
            : [];
        const inPersonInstances: CourseInstance[] = course.cities.map((city) => {
            return {
                '@type': 'CourseInstance',
                courseMode: 'Onsite',
                courseWorkload: workload,
                location: city.name,
            };
        });
        return [...onlineInstance, ...inPersonInstances];
    }

    if (!occurrences.length) {
        return [
            {
                '@type': 'CourseInstance',
                courseMode: getCourseMode(course),
                courseWorkload: 'PT',
            },
        ];
    }

    return occurrences.map((occurrence) => {
        const location = occurrence?.location?.city || course.cities[0]?.name;
        return {
            '@type': 'CourseInstance',
            courseWorkload: getWorkload((occurrence.end_datetime - occurrence.start_datetime) / 60),
            startDate: Temporal.Instant.from(occurrence.times.start.zoned_datetime).toString(),
            endDate: Temporal.Instant.from(occurrence.times.end.zoned_datetime).toString(),
            courseMode: getCourseMode(course, occurrence),
            ...(location ? { location } : {}),
        };
    });
};

const getCourseSchema = (
    course: SearchIndexCourse,
    occurrences?: SearchIndexClassCourse['occurrences']
): CourseSchema => {
    const providerSchema: ProviderTypes = {
        '@type': 'EducationalOrganization',
        '@id': `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/${course.educator.slug}`,
        name: course.educator.name,
        sameAs: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/${course.educator.slug}`,
        url: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/${course.educator.slug}`,
    };

    const sharedSchema: SharedSchemaTypes = {
        name: course.name,
        description:
            course.meta_description ||
            course.description_plaintext ||
            html2PlainText(course.description, html2PlainTextOptions),
        image: course.image_url || fallbackImageUrl,
        offers: (course.offers || []).map((offer): Offer => {
            return {
                '@type': 'Offer',
                '@id': offer._id,
                name: `${course.name} - ${offer.name}`,
                category: offer.price ? 'Paid' : 'Free',
                price: offer.price,
                priceCurrency: 'GBP',
                availability: 'InStock',
                url: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/${course.educator.slug}/${course.slug}`,
            };
        }),
    };

    const courseSchema: CourseSchema = {
        '@type': 'Course',
        '@id': `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/${course.educator.slug}/${course.slug}`,
        url: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/${course.educator.slug}/${course.slug}`,
        provider: providerSchema,
        hasCourseInstance: getInstances(course, occurrences || []),
        ...(course.reviews && course.reviews.count > 0
            ? {
                  aggregateRating: {
                      '@type': 'AggregateRating',
                      ratingValue: course.reviews.rating,
                      ratingCount: course.reviews.count,
                  },
              }
            : {}),
        ...sharedSchema,
    };

    return courseSchema;
};

export default getCourseSchema;
