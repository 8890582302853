export type Boosts = {
    NAME_BOOST: number;
    PARTIAL_NAME_BOOST: number;
    CATEGORY_NAME_BOOST: number;
    TAG_NAME_BOOST: number;
    DESCRIPTION_BOOST: number;
    PARTIAL_DESCRIPTION_BOOST: number;
    META_DESCRIPTION_BOOST: number;
    PARTIAL_META_DESCRIPTION_BOOST: number;
    LOCATION_BOOST: number;
    COURSE_RATING_BOOST: number;
    EDUCATOR_RATING_BOOST: number;
    DATE_BOOST: number;
    IS_BOOKABLE_BOOST: number;
    CITY_NAME_BOOST: number;
    MAX_PRICE_BOOST: number;
    HAS_AVAILABLE_SPACES: number;
    INTERNAL_RATING: number;
    HOSTED_ON_CADEMY: number;
};

export const DEFAULT_BOOSTS: Boosts = {
    NAME_BOOST: 10,
    PARTIAL_NAME_BOOST: 1,
    CATEGORY_NAME_BOOST: 10,
    TAG_NAME_BOOST: 1,
    DESCRIPTION_BOOST: 10,
    PARTIAL_DESCRIPTION_BOOST: 1,
    META_DESCRIPTION_BOOST: 1,
    PARTIAL_META_DESCRIPTION_BOOST: 1,
    LOCATION_BOOST: 1,
    COURSE_RATING_BOOST: 1,
    EDUCATOR_RATING_BOOST: 1,
    DATE_BOOST: 999,
    IS_BOOKABLE_BOOST: 1,
    CITY_NAME_BOOST: 1,
    MAX_PRICE_BOOST: 10,
    HAS_AVAILABLE_SPACES: 1,
    INTERNAL_RATING: 1,
    HOSTED_ON_CADEMY: 1,
};
