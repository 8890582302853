import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { LegacySearchIndexCourse } from '@shared/types';
import { PublicCourse } from '@cademy-package/sdk-component-types';

type VATLabelProps = {
    course: PublicCourse | LegacySearchIndexCourse;
    priceLabel: string;
    showVATLabel?: boolean;
};

export const VATLabel: FunctionComponent<VATLabelProps> = ({
    course,
    priceLabel,
    showVATLabel,
}) => {
    if (course.type === 'on-request' && course.pricing_option) {
        if (['free', 'price-on-enquiry'].includes(course.pricing_option.type)) {
            return null;
        }
    }

    if (course.currency !== 'GBP') {
        return null;
    }

    if (priceLabel.toLowerCase() === 'free') {
        return null;
    }

    if (showVATLabel) {
        return <span className={styles.vatLabel}>+ VAT</span>;
    }

    return null;
};
