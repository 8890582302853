import { DeliveryMethod } from '@shared/types';
import { FunctionComponent, useCallback, useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import MapMarkerAltSolidIcon from '../Icons/MapMarkerAltSolidIcon';
import CameraWebSolidIcon from '../Icons/CameraWebSolidIcon';
import LaptopSolidIcon from '../Icons/LaptopSolidIcon';

export const DeliveryIndicator: FunctionComponent<{
    delivery: DeliveryMethod | Array<DeliveryMethod>;
    locations?: Array<string | undefined>;
    courseKeyword?: string;
}> = ({ delivery, locations, courseKeyword }) => {
    const isMethod = useCallback(
        (method: DeliveryMethod) => {
            if (Array.isArray(delivery)) {
                return delivery.includes(method);
            }
            return delivery === method;
        },
        [delivery]
    );

    const keyword = useMemo(() => {
        if (!courseKeyword) {
            return '';
        }
        return ` ${courseKeyword}`;
    }, [courseKeyword]);

    const locationString = useMemo(() => {
        if (!locations) {
            return '';
        }
        const definedLocations = locations.filter((city): city is string => city !== undefined);
        if (!definedLocations.length) {
            return '';
        }
        if (definedLocations.length === 1) {
            return `${definedLocations[0]}`;
        }
        return `${definedLocations[0]} & ${definedLocations.length - 1} more`;
    }, [locations]);

    if (
        (Array.isArray(delivery) && delivery.length > 1 && !isMethod('on-request')) ||
        (isMethod('on-request') && isMethod('online') && isMethod('in-person'))
    ) {
        return (
            <span className={styles.delivery_indicator}>
                <span className="screen_reader_hidden">
                    Delivered in {locationString || 'person'} or Online
                </span>
                <span aria-hidden="true" className={classNames(styles.badge, styles.green)}>
                    <MapMarkerAltSolidIcon height={12} width={12} />
                    {locationString || 'In-person'}
                </span>
                <span aria-hidden="true">or</span>
                <span aria-hidden="true" className={classNames(styles.badge, styles.purple)}>
                    <CameraWebSolidIcon height={12} width={12} />
                    Online
                </span>
            </span>
        );
    }

    if (isMethod('online')) {
        return (
            <span className={styles.delivery_indicator}>
                <span className="screen_reader_hidden">Delivered Online</span>
                <span aria-hidden="true" className={classNames(styles.badge, styles.purple)}>
                    <CameraWebSolidIcon height={12} width={12} />
                    Online{keyword}
                </span>
            </span>
        );
    }

    if (isMethod('on-demand')) {
        return (
            <span className={styles.delivery_indicator}>
                <span className="screen_reader_hidden">Delivered Online On Demand</span>
                <span aria-hidden="true" className={classNames(styles.badge, styles.blue)}>
                    <LaptopSolidIcon height={12} width={12} />
                    On-demand {keyword}
                </span>
            </span>
        );
    }

    if (isMethod('hybrid')) {
        return (
            <span className={styles.delivery_indicator}>
                <span className="screen_reader_hidden">
                    {locationString
                        ? `Delivered Online & In-Person in ${locationString}`
                        : 'Delivered Online & In-Person'}
                </span>
                <span aria-hidden="true" className={classNames(styles.badge, styles.green)}>
                    <MapMarkerAltSolidIcon height={12} width={12} />
                    {locationString ? `${locationString} & Online` : `Online & In-Person${keyword}`}
                </span>
            </span>
        );
    }

    if (isMethod('in-person')) {
        return (
            <span className={styles.delivery_indicator}>
                <span className="screen_reader_hidden">
                    {locationString
                        ? `Delivered In-Person in ${locationString}`
                        : 'Delivered In-Person'}
                </span>
                <span aria-hidden="true" className={classNames(styles.badge, styles.green)}>
                    <MapMarkerAltSolidIcon height={12} width={12} />
                    {locationString ? `${locationString}` : `In-Person${keyword}`}
                </span>
            </span>
        );
    }

    // Should never get here
    return null;
};

export default DeliveryIndicator;
