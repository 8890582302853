const SECOND = 1;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * 30;

const getHourFormat = (seconds: number): string => {
    const hours = Math.floor(seconds / HOUR);
    const hoursLabel = hours === 1 ? 'hour' : 'hours';
    const remainingSeconds = seconds % HOUR;
    const remainingMinutes = Math.ceil(remainingSeconds / MINUTE);
    if (!remainingMinutes) {
        return `${hours} ${hoursLabel}`;
    }
    return `${hours} ${hoursLabel} ${remainingMinutes} minutes`;
};

export const getDuration = (seconds: number, isOnDemand?: boolean): string => {
    if (seconds < SECOND) {
        return '';
    }
    if (seconds < HOUR) {
        return `${Math.ceil(seconds / MINUTE)} minutes`;
    }
    if (seconds < HOUR * 7) {
        return getHourFormat(seconds);
    }

    if (seconds <= DAY) {
        return isOnDemand && isOnDemand === true ? getHourFormat(seconds) : 'Full day';
    }

    if (seconds <= DAY * 2) {
        return isOnDemand && isOnDemand === true ? getHourFormat(seconds) : 'Two days';
    }

    if (seconds <= WEEK * 2) {
        return `${Math.ceil(((seconds / DAY) * 100) / 100)} days`;
    }

    if (seconds <= WEEK * 20) {
        const weeks = Math.ceil(((seconds / WEEK) * 100) / 100);
        return `${weeks} weeks`;
    }

    const daysRemainder = seconds % MONTH;
    if (daysRemainder >= DAY * 8) {
        return `${Math.ceil(((seconds / MONTH) * 100) / 100)} months`;
    } else {
        return `${Math.floor(((seconds / MONTH) * 100) / 100)} months`;
    }
};
