export const renderPrice = (price: number, currency: string = 'GBP'): string => {
    if (Number.isInteger(price)) {
        try {
            return new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currencyDisplay: 'narrowSymbol',
                currency,
                maximumFractionDigits: 0,
            }).format(price);
        } catch (error) {
            if (error instanceof RangeError) {
                console.error(error);
                return new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currencyDisplay: 'narrowSymbol',
                    currency,
                }).format(price);
            }
            throw error;
        }
    }
    return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency,
    }).format(price);
};
