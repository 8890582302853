import { SearchIndexCourse, SearchIndexCourseFilters } from '@shared/types';
import { CademyError } from '@shared/domain-shared';
import { GeneralSearchError } from '../errors';
import { SearchIndexSortBy } from '../shared';
import { SearchResultCourse, convertSearchIndexCourse } from '../../convertSearchIndexCourse';
import { DEFAULT_BOOSTS } from '../../../pages_components/courses/DebugSearchWeights/boosts';

export type SearchIndexCourseResult = {
    results: Array<SearchResultCourse>;
    page: number;
    nextPage: number;
    totalPages: number;
    totalResults: number;
};

export type rawSearchIndexCourseResult = {
    results: Array<SearchIndexCourse>;
    page: number;
    nextPage: number;
    totalPages: number;
    totalResults: number;
};

const getBoosts = () => {
    if (typeof window === 'undefined') {
        return undefined;
    }
    const localStorageBoosts = window.localStorage.getItem('search-boosts');
    if (localStorageBoosts) {
        return { ...DEFAULT_BOOSTS, ...JSON.parse(localStorageBoosts) };
    }
    return undefined;
};

export const CourseSearch = async (
    search: string,
    filters: SearchIndexCourseFilters,
    page: number,
    perPage: number,
    sort_by: SearchIndexSortBy = 'relevance',
    isDebug: boolean = false
): Promise<SearchIndexCourseResult> => {
    const url = `${process.env.NEXT_PUBLIC_CADEMY_API_URL}/search-courses?page=${page}&perPage=${perPage}`;
    const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            search,
            filters: {
                ...filters,
                range: filters.range
                    ? {
                          from: filters.range.from.toISOString(),
                          to: filters.range.to.toISOString(),
                      }
                    : undefined,
            },
            sort_by,
            boosts: isDebug ? getBoosts() : undefined,
        }),
        credentials: 'include',
    });
    if (response.ok === false) {
        if (response.headers.get('content-type') === 'application/problem+json') {
            const problemDetails = await response.json();
            const error = CademyError.fromObject(problemDetails);
            throw error;
        }
        if (response.headers.get('content-type') === 'application/json') {
            const errorDetails = await response.json();
            throw new GeneralSearchError(errorDetails.safe);
        }
        const error = await response.text();
        console.error(new Error(`${response.status} - ${error}`));
        throw new GeneralSearchError();
    }
    const result = (await response.json()) as {
        totalItems: number;
        totalPages: number;
        page: number;
        data: Array<SearchIndexCourse>;
    };
    return {
        results: result.data.map(convertSearchIndexCourse(filters)),
        page: result.page,
        nextPage:
            result.totalPages !== result.page && result.totalPages !== 0
                ? result.page + 1
                : result.page,
        totalPages: result.totalPages,
        totalResults: result.totalItems,
    };
};

export const rawCourseSearch = async (
    search: string,
    filters: SearchIndexCourseFilters,
    page: number,
    perPage: number,
    sort_by: SearchIndexSortBy = 'relevance',
    isDebug: boolean = false
): Promise<rawSearchIndexCourseResult> => {
    const url = `${process.env.NEXT_PUBLIC_CADEMY_API_URL}/search-courses?page=${page}&perPage=${perPage}`;
    const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            search,
            filters: {
                ...filters,
                range: filters.range
                    ? {
                          from: filters.range.from.toISOString(),
                          to: filters.range.to.toISOString(),
                      }
                    : undefined,
            },
            sort_by,
            boosts: isDebug ? getBoosts() : undefined,
        }),
        credentials: 'include',
    });
    if (response.ok === false) {
        if (response.headers.get('content-type') === 'application/problem+json') {
            const problemDetails = await response.json();
            const error = CademyError.fromObject(problemDetails);
            throw error;
        }
        if (response.headers.get('content-type') === 'application/json') {
            const errorDetails = await response.json();
            throw new GeneralSearchError(errorDetails.safe);
        }
        const error = await response.text();
        console.error(new Error(`${response.status} - ${error}`));
        throw new GeneralSearchError();
    }
    const result = (await response.json()) as {
        totalItems: number;
        totalPages: number;
        page: number;
        data: Array<SearchIndexCourse>;
    };
    return {
        results: result.data,
        page: result.page,
        nextPage:
            result.totalPages !== result.page && result.totalPages !== 0
                ? result.page + 1
                : result.page,
        totalPages: result.totalPages,
        totalResults: result.totalItems,
    };
};
