type Geo = {
    latitude: number;
    longitude: number;
};

export const calculateGeoDistance = (geo1: Geo, geo2: Geo): number => {
    const latitudeDifference = Math.abs(geo1.latitude - geo2.latitude);
    const longitudeDifference = Math.abs(geo1.longitude - geo2.longitude);
    return latitudeDifference + longitudeDifference;
};
