'use client';
import { useEffect, useMemo, useState } from 'react';

export type useLocaleResult = { locale: string; isHydrated: boolean };

/**
 * This hook will provide the default locale when rendered on the server and once hydrated on the
 * client it will return the browsers local locale.
 */
export const useLocale = (defaultLocale: string = 'en-US'): useLocaleResult => {
    const [locale, setLocale] = useState<string>(defaultLocale);
    const [isHydrated, setIsHydrated] = useState<boolean>(false);

    useEffect(() => {
        setIsHydrated(true);
    }, []);

    useEffect(() => {
        if (isHydrated === false) {
            return;
        }
        setLocale(Intl.DateTimeFormat().resolvedOptions().locale);
    }, [isHydrated]);

    const result = useMemo(() => {
        return {
            locale,
            isHydrated,
        };
    }, [locale, isHydrated]);

    return result;
};
