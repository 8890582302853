'use client';

import { LegacySearchIndexCourse } from '@shared/types';
import { FunctionComponent, HTMLAttributes } from 'react';
import CourseDateText from '../CourseDateText';
import styles from './styles.module.scss';
import CalendarSolidIcon from '../Icons/CalendarSolidIcon';
import ClockSolidIcon from '../Icons/ClockSolidIcon';
import { getDuration } from '../../services/getDuration';

export type CourseTaglineProps = Omit<HTMLAttributes<HTMLParagraphElement>, 'children'> & {
    course: LegacySearchIndexCourse;
};

const ON_REQUEST_START_DATE = 9999999999;

export const CourseTagLine: FunctionComponent<CourseTaglineProps> = ({ course }) => {
    if (course.type === 'on-request') {
        return (
            <span className={styles.tagline_container}>
                <span className={styles.badge}>
                    <CalendarSolidIcon height={12} width={12} />
                    <span>Flexible Dates</span>
                </span>
            </span>
        );
    }

    if (course.delivery.includes('on-demand')) {
        if (course.duration) {
            return (
                <span className={styles.tagline_container}>
                    <span className={styles.badge}>
                        <ClockSolidIcon height={12} width={12} />
                        <span>{getDuration(course.duration * 60, true)}</span>
                    </span>
                </span>
            );
        }
        return null;
    }

    if (course.start_datetime === ON_REQUEST_START_DATE) {
        if (course.enquiry_types.length > 0) {
            return (
                <span className={styles.tagline_container}>
                    <span className={styles.badge}>
                        <CalendarSolidIcon height={12} width={12} />
                        <span>Flexible Dates</span>
                    </span>
                </span>
            );
        }
        return (
            <span className={styles.tagline_container}>
                <span className={styles.badge}>Join Waitlist</span>
            </span>
        );
    }

    if (course.start_datetime) {
        return (
            <span className={styles.tagline_container}>
                <span className={styles.badge}>
                    <CalendarSolidIcon height={12} width={12} />
                    <CourseDateText course={course} />
                </span>
            </span>
        );
    }

    return null;
};

export default CourseTagLine;
