import {
    LegacySearchIndexCourse,
    SearchIndexBlendedCourse,
    SearchIndexClassCourse,
    SearchIndexCourse,
    SearchIndexCourseFilters,
    SearchIndexOnDemandCourse,
    SearchIndexOnRequestCourse,
} from '@shared/types';
import { Course as CourseSchema } from 'schema-dts';
import { calculateGeoDistance } from './calculateGeoDistance';
import getCourseSchema from './getCourseSchema';
import { sortOccurrencesByFilters } from './sortOccurrencesByFilters';

const ON_REQUEST_START_DATE = 9999999999;

export type SearchResultCourse = LegacySearchIndexCourse & { schema?: CourseSchema } & {
    occurrences?: Array<LegacySearchIndexCourse>;
};

const convertOccurrence = (
    course: SearchIndexCourse,
    occurrence: SearchIndexClassCourse['occurrences'][number]
): LegacySearchIndexCourse => {
    return {
        _id: occurrence._id,
        type: course.type,
        level: course.level,
        available_spaces: occurrence.available_spaces,
        block_dates: 0,
        meta_description: course.meta_description,
        categories: course.categories.map((category) => {
            return {
                _id: category._id,
                name: category.name,
                slug: category.slug,
                parent_refs: [],
                is_grouping_category: false,
                created_at: 0,
                updated_at: 0,
            };
        }),
        city: occurrence?.location?.city || null,
        cities: [occurrence?.location?.city].filter((city, index, cities) => {
            return cities.indexOf(city) === index && city !== undefined;
        }),
        collection_refs: course.collection_refs,
        course_ref: course._id,
        delivery: occurrence.delivery ? [occurrence.delivery] : [],
        description: course.description,
        description_plaintext: course.description_plaintext,
        duration: occurrence.end_datetime - occurrence.start_datetime,
        educator_name: course.educator.name,
        educator_ref: course.educator._id,
        educator_slug: course.educator.slug,
        enquiry_types: course.enquiry_types,
        featured: false,
        future_dates: false,
        image_url: course.image_url,
        img_urls: [course.image_url],
        last_refreshed_at: 0,
        marketplace_tags: course.marketplace_tags.map((tag) => {
            return {
                _id: tag._id,
                created_at: 0,
                is_system: true,
                name: tag.name,
                slug: tag.slug,
                synonyms: [],
                updated_at: 0,
            };
        }),
        max_price: occurrence.max_price || 0,
        min_price: occurrence.min_price || 0,
        name: course.name,
        occurrence_ref: occurrence._id,
        price: occurrence.offers.map((offer) => offer.price || 0) || [],
        reviews: course.reviews.rating > 0 ? course.reviews : course.educator_reviews,
        search_score: course.meta.score,
        slug: course.slug,
        start_datetime: occurrence.start_datetime,
        start_datetimes: [occurrence.start_datetime],
        list_refs: course.list_refs,
        educator_travels: false,
        keyword: course.keyword,
        pricing_option: null,
        student_travels: false,
        available_internationally: course.available_internationally,
        available_uk_wide: course.available_uk_wide,
        times: {
            nextStart: occurrence.times.start,
            futureOccurrenceCount: 0,
        },
        currency: occurrence.currency,
    };
};

const convertClassOrBlendedSearchIndexCourse = (
    filters: SearchIndexCourseFilters,
    result: SearchIndexClassCourse | SearchIndexBlendedCourse
): SearchResultCourse => {
    const now = Date.now() / 1000;
    const futureOccurrences = result.occurrences.filter(
        (occurrence) => occurrence.start_datetime >= now
    );
    const sortedOccurrences = sortOccurrencesByFilters(filters, futureOccurrences);
    const closestMatchingOccurrence = sortedOccurrences[0] || undefined;

    return {
        _id: result._id,
        type: result.type,
        level: result.level,
        available_spaces: null,
        block_dates: 0,
        meta_description: result.meta_description,
        categories: result.categories.map((category) => {
            return {
                _id: category._id,
                name: category.name,
                slug: category.slug,
                parent_refs: [],
                is_grouping_category: false,
                created_at: 0,
                updated_at: 0,
            };
        }),
        city:
            closestMatchingOccurrence?.location?.city ||
            futureOccurrences.find((occurrence) => occurrence.location?.city)?.location?.city ||
            null,
        cities: [
            ...result.cities.map((city) => city.name),
            ...futureOccurrences.map((occurrence) => occurrence.location?.city),
        ].filter((city, index, cities) => {
            return cities.indexOf(city) === index && city !== undefined;
        }),
        collection_refs: result.collection_refs,
        course_ref: result._id,
        delivery: closestMatchingOccurrence?.delivery
            ? Array.from(new Set([closestMatchingOccurrence.delivery, ...result.delivery_methods]))
            : result.delivery_methods,
        description: result.description,
        description_plaintext: result.description_plaintext,
        duration: closestMatchingOccurrence
            ? closestMatchingOccurrence.end_datetime - closestMatchingOccurrence.start_datetime
            : null,
        educator_name: result.educator.name,
        educator_ref: result.educator._id,
        educator_slug: result.educator.slug,
        enquiry_types: result.enquiry_types,
        featured: false,
        future_dates: futureOccurrences.length > 0,
        image_url: result.image_url,
        img_urls: [result.image_url],
        last_refreshed_at: 0,
        marketplace_tags: result.marketplace_tags.map((tag) => {
            return {
                _id: tag._id,
                created_at: 0,
                is_system: true,
                name: tag.name,
                slug: tag.slug,
                synonyms: [],
                updated_at: 0,
            };
        }),
        max_price: result.max_price || 0,
        min_price: result.min_price || 0,
        name: result.name,
        occurrence_ref: closestMatchingOccurrence?._id || null,
        price: closestMatchingOccurrence?.offers.map((offer) => offer.price || 0) || [],
        reviews: result.reviews.rating > 0 ? result.reviews : result.educator_reviews,
        search_score: result.meta.score,
        slug: result.slug,
        start_datetime: closestMatchingOccurrence?.start_datetime || ON_REQUEST_START_DATE,
        start_datetimes: futureOccurrences
            .map((occurrence) => occurrence.start_datetime)
            .filter((time, index, startTimes) => {
                return startTimes.indexOf(time) === index;
            }),
        times: closestMatchingOccurrence
            ? {
                  nextStart: closestMatchingOccurrence.times.start,
                  futureOccurrenceCount: futureOccurrences
                      .map((occurrence) => occurrence.start_datetime)
                      .filter((time, index, startTimes) => {
                          return startTimes.indexOf(time) === index;
                      }).length,
              }
            : undefined,
        list_refs: result.list_refs,
        educator_travels: false,
        keyword: result.keyword,
        pricing_option: null,
        student_travels: false,
        available_internationally: result.available_internationally,
        available_uk_wide: result.available_uk_wide,
        occurrences: sortedOccurrences.map((occurrence) => convertOccurrence(result, occurrence)),
        schema: getCourseSchema(result, futureOccurrences),
        currency: result.currency,
    };
};

const convertOnDemandSearchIndexCourse = (
    filters: SearchIndexCourseFilters,
    result: SearchIndexOnDemandCourse
): SearchResultCourse => {
    return {
        _id: result._id,
        type: result.type,
        level: result.level,
        available_spaces: null,
        block_dates: 0,
        meta_description: result.meta_description,
        categories: result.categories.map((category) => {
            return {
                _id: category._id,
                name: category.name,
                slug: category.slug,
                parent_refs: [],
                is_grouping_category: false,
                created_at: 0,
                updated_at: 0,
            };
        }),
        city: null,
        cities: [],
        collection_refs: result.collection_refs,
        course_ref: result._id,
        delivery: result.delivery_methods,
        description: result.description,
        duration: result.duration || null,
        description_plaintext: result.description_plaintext,
        educator_name: result.educator.name,
        educator_ref: result.educator._id,
        educator_slug: result.educator.slug,
        enquiry_types: result.enquiry_types,
        featured: false,
        future_dates: true,
        image_url: result.image_url,
        img_urls: [result.image_url],
        last_refreshed_at: 0,
        marketplace_tags: result.marketplace_tags.map((tag) => {
            return {
                _id: tag._id,
                created_at: 0,
                is_system: true,
                name: tag.name,
                slug: tag.slug,
                synonyms: [],
                updated_at: 0,
            };
        }),
        max_price: result.max_price || 0,
        min_price: result.min_price || 0,
        name: result.name,
        occurrence_ref: null,
        price: result.offers?.map((offer) => offer.price || 0) || 0,
        reviews: result.reviews.rating > 0 ? result.reviews : result.educator_reviews,
        search_score: result.meta.score,
        slug: result.slug,
        start_datetime: ON_REQUEST_START_DATE,
        start_datetimes: [],
        list_refs: result.list_refs,
        educator_travels: false,
        keyword: result.keyword,
        pricing_option: null,
        student_travels: false,
        available_internationally: result.available_internationally,
        available_uk_wide: result.available_uk_wide,
        schema: getCourseSchema(result),
        currency: result.currency,
    };
};

const convertOnRequestSearchIndexCourse = (
    filters: SearchIndexCourseFilters,
    result: SearchIndexOnRequestCourse
): SearchResultCourse => {
    const sortByDistanceFrom =
        (target: SearchIndexCourseFilters['geo_coordinates']) =>
        (
            cityOne: SearchIndexOnRequestCourse['cities'][number],
            cityTwo: SearchIndexOnRequestCourse['cities'][number]
        ) => {
            if (!target) {
                return 0;
            }
            const distanceOne = calculateGeoDistance(target, cityOne.geo_coordinates);
            const distanceTwo = calculateGeoDistance(target, cityTwo.geo_coordinates);
            if (distanceOne > distanceTwo) {
                return 1;
            }
            if (distanceTwo > distanceOne) {
                return -1;
            }
            return 0;
        };

    const sortedCities = [...result.cities].sort(sortByDistanceFrom(filters.geo_coordinates));
    return {
        _id: result._id,
        type: result.type,
        level: result.level,
        available_spaces: null,
        block_dates: 0,
        meta_description: result.meta_description,
        categories: result.categories.map((category) => {
            return {
                _id: category._id,
                name: category.name,
                slug: category.slug,
                parent_refs: [],
                is_grouping_category: false,
                created_at: 0,
                updated_at: 0,
            };
        }),
        city: sortedCities[0]?.name || null,
        cities: sortedCities
            .filter((city, index, cities) => {
                return cities.indexOf(city) === index && city !== undefined;
            })
            .map((city) => city.name),
        collection_refs: result.collection_refs,
        course_ref: result._id,
        delivery: result.delivery_methods,
        description: result.description,
        description_plaintext: result.description_plaintext,
        duration: null,
        educator_name: result.educator.name,
        educator_ref: result.educator._id,
        educator_slug: result.educator.slug,
        enquiry_types: result.enquiry_types,
        featured: false,
        future_dates: true,
        image_url: result.image_url,
        img_urls: [result.image_url],
        last_refreshed_at: 0,
        marketplace_tags: result.marketplace_tags.map((tag) => {
            return {
                _id: tag._id,
                created_at: 0,
                is_system: true,
                name: tag.name,
                slug: tag.slug,
                synonyms: [],
                updated_at: 0,
            };
        }),
        max_price: result.max_price || 0,
        min_price: result.min_price || 0,
        name: result.name,
        occurrence_ref: null,
        price:
            result.pricing_option.type === 'price-offers'
                ? result.pricing_option.offers.map((offer) => offer.price || 0)
                : [],
        reviews: result.reviews.rating > 0 ? result.reviews : result.educator_reviews,
        search_score: result.meta.score,
        slug: result.slug,
        start_datetime: ON_REQUEST_START_DATE,
        start_datetimes: [],
        list_refs: result.list_refs,
        educator_travels: result.educator_travels,
        keyword: result.keyword,
        pricing_option: result.pricing_option,
        student_travels: result.student_travels,
        available_internationally: result.available_internationally,
        available_uk_wide: result.available_uk_wide,
        schema: getCourseSchema(result),
        currency: result.currency,
    };
};

export const convertSearchIndexCourse =
    (filters: SearchIndexCourseFilters) =>
    (result: SearchIndexCourse): SearchResultCourse => {
        switch (result.type) {
            case 'class':
                return convertClassOrBlendedSearchIndexCourse(filters, result);
            case 'blended':
                return convertClassOrBlendedSearchIndexCourse(filters, result);
            case 'on-demand':
                return convertOnDemandSearchIndexCourse(filters, result);
            case 'on-request':
                return convertOnRequestSearchIndexCourse(filters, result);
        }
    };
