import { SearchIndexClassCourse, SearchIndexCourseFilters } from '@shared/types';
import { calculateGeoDistance } from '../calculateGeoDistance';

const occurrenceHasDelivery = (
    delivery: SearchIndexCourseFilters['delivery'],
    occurrence: SearchIndexClassCourse['occurrences'][number]
): boolean => {
    if (!delivery) {
        return false;
    }
    if (delivery === 'on-demand') {
        return false;
    }
    switch (delivery) {
        case 'in-person':
            return occurrence.delivery === 'in-person';
        case 'online':
            return occurrence.delivery === 'online';
        case 'live-online':
            return occurrence.delivery === 'online';
    }
};

export const sortOccurrencesByFilters = (
    filters: SearchIndexCourseFilters,
    occurrences: SearchIndexClassCourse['occurrences']
): SearchIndexClassCourse['occurrences'] => {
    const sorted = [...occurrences].sort((occurrence1, occurrence2) => {
        const geoDistance1 =
            filters.geo_coordinates && occurrence1.location?.geo_coordinates
                ? calculateGeoDistance(
                      filters.geo_coordinates,
                      occurrence1.location.geo_coordinates
                  )
                : Infinity;
        const geoDistance2 =
            filters.geo_coordinates && occurrence2.location?.geo_coordinates
                ? calculateGeoDistance(
                      filters.geo_coordinates,
                      occurrence2.location.geo_coordinates
                  )
                : Infinity;

        const hasDelivery1 = occurrenceHasDelivery(filters.delivery, occurrence1);
        const hasDelivery2 = occurrenceHasDelivery(filters.delivery, occurrence2);

        const time1 = occurrence1.start_datetime;
        const time2 = occurrence2.start_datetime;

        const totalScore1 =
            (geoDistance1 < geoDistance2 ? 3 : 0) +
            (hasDelivery1 && !hasDelivery2 ? 2 : 0) +
            (time1 < time2 ? 1 : 0);

        const totalScore2 =
            (geoDistance2 < geoDistance1 ? 3 : 0) +
            (hasDelivery2 && !hasDelivery1 ? 2 : 0) +
            (time2 < time1 ? 1 : 0);

        if (totalScore1 > totalScore2) {
            return -1;
        }
        if (totalScore2 > totalScore1) {
            return 1;
        }
        return 0;
    });
    return sorted;
};
