'use client';
import { FunctionComponent, Suspense, useMemo } from 'react';
import { useLocale } from '../../hooks/useLocale';
import { Temporal } from 'temporal-polyfill';

export type TimezoneDateProps = {
    date: Temporal.ZonedDateTime | string;
    format: Omit<Intl.DateTimeFormatOptions, 'timeZone'>;
};

export const TimezoneDate: FunctionComponent<TimezoneDateProps> = ({ date: _date, format }) => {
    const { locale } = useLocale();

    const date = useMemo(() => Temporal.ZonedDateTime.from(_date), [_date]);

    const formattedDate = useMemo(() => {
        return date.toLocaleString(locale, format);
    }, [locale, date, format]);

    return (
        <Suspense key={locale}>
            <time dateTime={date.toInstant().toString()}>{formattedDate}</time>
        </Suspense>
    );
};
