import { LegacySearchIndexCourse } from '@shared/types';
import { FunctionComponent, HTMLAttributes, useMemo } from 'react';
import { VATLabel } from '../VATLabel';
import { renderPrice } from '../../services/renderPrice';

type SearchIndexCoursePriceProps = Omit<HTMLAttributes<HTMLSpanElement>, 'children'> & {
    course: LegacySearchIndexCourse;
    showVATLabel?: boolean;
};

export type CoursePriceProps = SearchIndexCoursePriceProps;

export const CoursePrice: FunctionComponent<CoursePriceProps> = ({
    course,
    showVATLabel,
    ...props
}) => {
    const minPrice: number = useMemo(() => {
        if (
            course.pricing_option?.type === 'free' ||
            course.pricing_option?.type === 'price-on-enquiry'
        ) {
            return 0;
        }
        if (course.pricing_option?.type === 'price-range') {
            return course.pricing_option.minimumPrice;
        }
        if (course.pricing_option?.type === 'price-offers') {
            const minimumOfferPrice = Math.min(
                ...course.pricing_option.offers.map((offer) => offer.price)
            );
            return isFinite(minimumOfferPrice) ? minimumOfferPrice : 0;
        }
        return course.min_price;
    }, [course]);

    const maxPrice: number = useMemo(() => {
        if (
            course.pricing_option?.type === 'free' ||
            course.pricing_option?.type === 'price-on-enquiry'
        ) {
            return 0;
        }
        if (course.pricing_option?.type === 'price-range') {
            return course.pricing_option.maximumPrice;
        }
        if (course.pricing_option?.type === 'price-offers') {
            const maximumOfferPrice = Math.max(
                ...course.pricing_option.offers.map((offer) => offer.price)
            );
            return isFinite(maximumOfferPrice) ? maximumOfferPrice : 0;
        }
        return course.max_price;
    }, [course]);

    const priceText = useMemo(() => {
        if (course.pricing_option?.type === 'free') {
            return 'FREE';
        }
        if (course.pricing_option?.type === 'price-on-enquiry') {
            return 'Price on Enquiry';
        }
        if (!minPrice && !maxPrice) {
            return 'FREE';
        }
        if (minPrice === maxPrice) {
            return renderPrice(minPrice, course.currency);
        }
        if (minPrice === 0) {
            return `FREE to ${renderPrice(maxPrice, course.currency)}`;
        }
        return `${renderPrice(minPrice, course.currency)} to ${renderPrice(maxPrice, course.currency)}`;
    }, [course, minPrice, maxPrice]);

    return (
        <div {...props}>
            <span>{priceText}</span>
            <VATLabel course={course} priceLabel={priceText} showVATLabel={showVATLabel} />
        </div>
    );
};
