'use client';
import { ComponentPropsWithoutRef, useContext, useMemo } from 'react';
import { AppContext } from '../../contexts/AppContext';
import Link from 'next/link';
import { buildMinisiteLink } from '../useMinisiteLink';

export const useCourseLink = (
    educatorSlug: string,
    courseSlug: string,
    occurrenceId?: string
): Pick<ComponentPropsWithoutRef<typeof Link>, 'href' | 'rel' | 'target'> => {
    const query = useMemo(() => {
        if (!occurrenceId) {
            return '';
        }
        return `?occurrence=${occurrenceId}`;
    }, [occurrenceId]);
    const appContext = useContext(AppContext);
    switch (appContext.context) {
        case 'marketplace': {
            return {
                href: `/${educatorSlug}/${courseSlug}${query}`,
            };
        }
        case 'minisite': {
            if (appContext.educatorSlug !== educatorSlug) {
                return {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                    href: buildMinisiteLink(educatorSlug, `/${courseSlug}${query}`),
                };
            }
            return {
                href: `/${courseSlug}${query}`,
            };
        }
        case 'embed': {
            return {
                href: `/embed/${educatorSlug}/${courseSlug}${query}`,
            };
        }
    }
};
