import { LegacySearchIndexCourse } from '@shared/types';
import { Temporal } from 'temporal-polyfill';
import { useMemo } from 'react';
import { TimezoneDate } from '../TimezoneDate';

const ON_REQUEST_START_DATE = 9999999999;

const CourseDateText = ({ course }: { course: LegacySearchIndexCourse }) => {
    const startDate = useMemo(() => {
        if (course.times) {
            return Temporal.ZonedDateTime.from(course.times.nextStart.zoned_datetime);
        }

        return undefined;
    }, [course]);

    if (course.delivery.length === 1 && course.delivery[0] === 'on-demand')
        return <span>Pre-recorded Online course</span>;

    if (!startDate) {
        return null;
    }

    if (course.start_datetime === ON_REQUEST_START_DATE) {
        if (course.enquiry_types.length > 0) return <>Flexible Dates</>;

        return null;
    }

    return (
        <>
            <TimezoneDate
                date={startDate}
                format={{
                    month: 'short',
                    weekday: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    timeZoneName: 'short',
                }}
            />{' '}
            {(course.times?.futureOccurrenceCount ?? 0) > 1 ? <>+ more</> : null}
        </>
    );
};

export default CourseDateText;
